import React from 'react';
import { useIntl } from 'react-intl';
import {
  EmptyComponent,
  TableCells,
  TableColumn,
  TableColumnAlign,
  TableFilterCells,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { CourtFile } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useCourtFileColumns } from '../court-file-columns';

export function useColumns(): TableColumn<CourtFile>[] {
  const intl = useIntl();
  const {
    columnJid,
    columnDeliveryDate,
    columnOpponent,
    columnProposers,
    columnType,
  } = useCourtFileColumns();

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_NEW__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.COURT_FILES_NEW}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    {
      datakey: 'incomingNumber',
      name: intl.formatMessage({
        id: 'ES__COURT_FILES_NEW__TABLE__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      width: 100,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.FilterTextCell,
      sortable: true,
      filterable: true,
    },
    columnJid,
    columnProposers,
    columnType,
    columnOpponent,
  ];
}
