import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormDateField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { RunningDeadlineField } from '@composite/deadlines/running-deadline-field';
import { NoteField } from '@composite/notes/note-field';
import { HighlightedDisabledComponent } from '@composite/settlement-method/highlighted-disabled-component';
import { ViewingField } from '@composite/viewing/viewing-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { EsFile } from '@models';
import { Agenda, Role, UIElement } from '@enums';
import { useSettlementMethods } from '../../../dict-settlement-method/dict-settlement-method-api';
import {
  NOTE_SOURCE,
  VIEWING_SOURCE,
} from '../../../es-file/es-file-hooked-sources';
import { useDisciplinaryProceedingsPermission } from '../../../es-file/es-file-permission';
import { useJudges } from '../../../judge/judge-api';
import { useUserRoles } from '../../../role/role-api';
import { useSubjects } from '../../../subject/subject-api';

export function GeneralFields() {
  const {
    disciplinaryProceedingsProposers: proposers,
    states,
    agendas,
  } = useContext(StaticDataContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } =
    useContext<DetailHandle<EsFile>>(DetailContext);

  const stateSource = useStaticListSource(states);
  const proposerSource = useStaticListSource(proposers);
  const agendaSource = useStaticListSource(agendas);

  const judges = useJudges();
  const subjects = useSubjects();
  const settlementMethods = useSettlementMethods(
    eqFilterParams({
      field: 'agenda.id',
      value: Agenda.DISCIPLINARY_PROCEEDINGS,
    })
  );

  const indexUsers = useUserRoles({
    filters: [
      {
        field: 'role.id',
        operation: ApiFilterOperation.EQ,
        value: Role.INDEX,
      },
      {
        field: 'agendas.id',
        operation: ApiFilterOperation.EQ,
        value: Agenda.DISCIPLINARY_PROCEEDINGS,
      },
    ],
  });

  /**
   * Permissions
   */
  const { hasPermission } = useDisciplinaryProceedingsPermission(source.data);

  /**
   * Viewing props.
   */
  const disabledViewings =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.DisciplinaryProceedingsFile.VIEWINGS);
  const viewingSource = hookedSources[VIEWING_SOURCE]?.source;

  /**
   * Note props.
   */
  const disabledNotes = isLocked || mode !== DetailMode.VIEW;
  const noteSource = hookedSources[NOTE_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Spis"
          />
        }
        bottomBorder={false}
      >
        <FormTextField
          name="number.value"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.FILE_NUMBER)
          }
        />
        <FormTextField
          name="jid"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__JID"
              defaultMessage="JID"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.BAR_CODE)
          }
        />
        <FormDateField
          name="created"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__DELIVERY_DATE"
              defaultMessage="Datum vytvoření"
            />
          }
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.CREATED_DATE)
          }
        />
        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__INF_FILES__DETAIL__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          required
          source={agendaSource}
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.AGENDA)
          }
          valueIsId={true}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__INF_FILES__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          source={stateSource}
          tooltipMapper={(o) => o.name}
          disabled={!hasPermission(UIElement.DisciplinaryProceedingsFile.STATE)}
          valueIsId={true}
        />
        <FormSelect
          name="proposer"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS__DETAIL__FIELD_LABEL__PROPOSER"
              defaultMessage="Navrhovatel"
            />
          }
          source={proposerSource}
          required={true}
          valueIsId={true}
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.PROPOSER)
          }
        />
        <FormAutocomplete
          name="participant"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS__DETAIL__FIELD_LABEL__PARTICIPANT"
              defaultMessage="Účastník"
            />
          }
          required={true}
          source={judges}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.PARTICIPANT)
          }
        />
        <FormAutocomplete
          name="legalAdviser"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS__DETAIL__FIELD_LABEL__LEGAL_ADVISER"
              defaultMessage="Právní zástupce"
            />
          }
          source={subjects}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.LEGAL_ADVISER)
          }
        />
        <FormAutocomplete
          name="indexUserRole"
          label={
            <FormattedMessage
              id="ES__PLENARY_OPINION_FILES__DETAIL__FIELD_LABEL__INDEX_USER_ROLE"
              defaultMessage="Vedoucí rejstříku"
            />
          }
          source={indexUsers}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile.INDEX_USER_ROLE
            )
          }
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__DECIDING_AUTHORITY_PANEL_TITLE"
            defaultMessage="Rozhodující orgán"
          />
        }
      >
        <FormAutocomplete
          name="decidingAuthority.chairman"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__CHAIRMAN"
              defaultMessage="Předseda"
            />
          }
          source={judges}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile.DECIDING_AUTHORITY
            )
          }
        />
        <FormAutocomplete
          name="decidingAuthority.decidingAuthorityMembers"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__MEMBERS"
              defaultMessage="Členové"
            />
          }
          multiple={true}
          source={judges}
          labelMapper={autocompleteLabelMapper}
          disabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile.DECIDING_AUTHORITY
            )
          }
        />
      </FormPanel>

      <ViewingField disabled={disabledViewings} source={viewingSource} />

      <FormPanel
        label={
          <FormattedMessage
            id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__OTHER_PANEL_TITLE"
            defaultMessage="Ostatní"
          />
        }
      >
        <FormAutocomplete
          name="settlementMethods"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__SETTLEMENT_METHOD"
              defaultMessage="Způsob vyřízení"
            />
          }
          multiple={true}
          labelMapper={autocompleteLabelMapper}
          source={settlementMethods}
          disabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile.SETTLEMENT_METHOD
            )
          }
          DisabledComponent={HighlightedDisabledComponent}
        />
        <FormDateField
          name="decisionDate"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__DECISION_DATE"
              defaultMessage="Datum rozhodnutí"
            />
          }
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.DECISION_DATE)
          }
        />
        <FormLocalDateTimeField
          name="closingDate"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__CLOSING_DATE"
              defaultMessage="Datum uzavření"
            />
          }
          disabled={
            !hasPermission(UIElement.DisciplinaryProceedingsFile.CLOSING_DATE)
          }
        />
        <FormDateField
          name="decisionDeliveryDate"
          label={
            <FormattedMessage
              id="ES__DISCIPLINARY_PROCEEDINGS_FILES__DETAIL__FIELD_LABEL__DECISION_DELIVERY_DATE"
              defaultMessage="Datum doručení rozhodnutí"
            />
          }
          disabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile.DECISION_DELIVERY_DATE
            )
          }
        />
      </FormPanel>
      {mode !== DetailMode.NEW && (
        <RunningDeadlineField
          disabled={
            !hasPermission(
              UIElement.DisciplinaryProceedingsFile.RUNNING_DEADLINES
            )
          }
        />
      )}
      <NoteField
        disabled={
          disabledNotes ||
          !hasPermission(UIElement.DisciplinaryProceedingsFile.NOTES)
        }
        source={noteSource}
      />
    </>
  );
}
