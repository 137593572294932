import { noop, stubFalse } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  EmptyComponent,
  EvidenceContext,
  FieldFilter,
  FormAutocomplete,
  FormCustomField,
  FormDateField,
  FormDateTimeField,
  FormDictionaryField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextField,
  TableField,
  TableFieldCells,
  TextField,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { RunningDeadlineField } from '@composite/deadlines/running-deadline-field';
import { NoteField } from '@composite/notes/note-field';
import { HighlightedDisabledComponent } from '@composite/settlement-method/highlighted-disabled-component';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { useAutoCollapse } from '@components/form/misc/auto-collapse-hook';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { FormTableCount } from '@components/form/table-field/form-table-count';
import { CourtFile, EsFile, Judge } from '@models';
import { Agenda, UIElement } from '@enums';
import { useProcessingTypes } from '../../../dict-processing-type/dict-processing-type-api';
import { useSettlementMethods } from '../../../dict-settlement-method/dict-settlement-method-api';
import { NOTE_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { useCourtFilePermission } from '../../../es-file/es-file-permission';
import { useJudgeSource, useJudges } from '../../../judge/judge-api';
import {
  useContestedOpinionsColumns,
  useMergedFilesColumns,
} from '../../court-file-columns';

export function GeneralFields() {
  /**
   * Context stuff.
   */
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode, source } =
    useContext<DetailHandle<EsFile>>(DetailContext);
  const { agendas, states, courtFileTypes, decisionForms, judgeFunctions } =
    useContext(StaticDataContext);

  /**
   * Data sources
   */
  const agendaSource = useStaticListSource(agendas);
  const courtFileTypeSource = useStaticListSource(courtFileTypes);
  const stateSource = useStaticListSource(states);
  const decisionFormsSource = useStaticListSource(decisionForms);

  const useJudgeFunctions = () => useStaticListSource(judgeFunctions);
  const judgesSource = useJudgeSource();
  const judges = useJudges();
  const settlementMethods = useSettlementMethods(
    eqFilterParams({ field: 'agenda.id', value: Agenda.COURT })
  );
  const processingTypes = useProcessingTypes();

  /**
   * Form selectors.
   */
  const {
    proceedingDuration,
    proceedingDurationCalculation,
    contestedOpinions,
    mergedFiles,
  } = useFormSelector((values: CourtFile) => ({
    contestedOpinions: values?.contestedOpinions ?? [],
    proceedingDuration: values?.proceedingDuration ?? '-',
    proceedingDurationCalculation: values?.proceedingDurationCalculation ?? '-',
    mergedFiles: [
      ...(values?.continuingFileFor ?? []),
      ...(values.mergedIn ? [values.mergedIn] : []),
    ],
  }));

  /**
   * ContestedOpinion props.
   */
  const { panelRef: contestedOpinionPanel } =
    useAutoCollapse(contestedOpinions);
  const contestedOpinionsColumns = useContestedOpinionsColumns();

  /**
   * Merged files props.
   */
  const { panelRef: mergedFilesPanel } = useAutoCollapse(mergedFiles);
  const mergedFilesColumns = useMergedFilesColumns();

  /**
   * Note props.
   */
  const disabledNotes = isLocked || mode !== DetailMode.VIEW;
  const noteSource = hookedSources[NOTE_SOURCE]?.source;

  /**
   * Permissions.
   */
  const { hasPermission } = useCourtFilePermission(source.data);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__COURT_FILES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Spis"
          />
        }
      >
        <FormTextField
          name="number.value"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.FILE_NUMBER)}
        />
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.BAR_CODE)}
        />
        <FormTextField
          name="evidenceNumber"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__EVIDENCE_NUMBER"
              defaultMessage="Evidenční číslo spisové služby"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.EVIDENCE_NUMBER)}
        />
        <FormTextField
          name="jid"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__JID"
              defaultMessage="JID"
            />
          }
          disabled={true}
        />
        <FormDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Datum vytvoření"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.CREATED)}
        />
        <FormDateField
          name="deliveryDate"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__DELIVERY_DATE"
              defaultMessage="Datum doručení"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.DELIVERY_DATE)}
          autoFocus={true}
          required={true}
        />
        <FormDateField
          name="statsDate"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__STATS_DATE"
              defaultMessage="Datum pro statistiku"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.STATS_DATE)}
          required={true}
        />
        <FormTextField
          name="incomingNumber"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__INCOMING_NUMBER"
              defaultMessage="Došlé číslo"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.INCOMING_NUMBER)}
          required={true}
        />
        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          source={agendaSource}
          disabled={!hasPermission(UIElement.CourtFile.AGENDA)}
          valueIsId={true}
        />
        <FormSelect
          name="type"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ soudního spisu"
            />
          }
          source={courtFileTypeSource}
          disabled={!hasPermission(UIElement.CourtFile.TYPE)}
          valueIsId={true}
          required={true}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          source={stateSource}
          tooltipMapper={(o) => o.name}
          disabled={!hasPermission(UIElement.CourtFile.STATE)}
          valueIsId={true}
        />
        <FormDictionaryField
          name="judge"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__JUDGE"
              defaultMessage="Soudce zpravodaj"
            />
          }
          dialogSource={judgesSource}
          autocompleteSource={judges}
          columns={[
            { name: 'Jméno', datakey: 'name', width: 300 },
            {
              name: 'Funkce',
              datakey: 'function',
              CellComponent:
                TableFieldCells.useSelectCellFactory(useJudgeFunctions),
              width: 300,
            },
          ]}
          filters={[
            {
              label: 'Jméno',
              datakey: 'name',
              filterkey: 'name',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
            {
              label: 'Funkce',
              datakey: 'function.id',
              filterkey: 'function.id',
              defaultOperation: ApiFilterOperation.CONTAINS,
              FilterComponent: FieldFilter.FilterTextCell,
            },
          ]}
          labelMapper={(value) =>
            (value as Judge)?.label ??
            `${(value as Judge)?.name ?? ''} ${(value as Judge)?.surname ?? ''}`
          }
          disabled={!hasPermission(UIElement.CourtFile.JUDGE)}
        />
        <FormAutocomplete
          name="processingType"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__PROCEEDING_TYPE"
              defaultMessage="Typ řízení"
            />
          }
          labelMapper={autocompleteLabelMapper}
          source={processingTypes}
          disabled={!hasPermission(UIElement.CourtFile.PROCESSING_TYPE)}
        />
        <FormAutocomplete
          name="settlementMethods"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__SETTLEMENT_METHOD"
              defaultMessage="Způsob vyřízení"
            />
          }
          required={true}
          multiple={true}
          labelMapper={autocompleteLabelMapper}
          source={settlementMethods}
          disabled={!hasPermission(UIElement.CourtFile.SETTLEMENT_METHODS)}
          DisabledComponent={HighlightedDisabledComponent}
        />
        <FormSelect
          name="decisionForm"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__DECISION_FORM"
              defaultMessage="Forma rozhodnutí"
            />
          }
          source={decisionFormsSource}
          valueIsId={true}
          disabled={!hasPermission(UIElement.CourtFile.DECISSION_FORM)}
        />
        <FormDateField
          name="discussionDate"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__DISCUSSION_DATE"
              defaultMessage="Datum projednání"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.DISCUSSION_DATE)}
        />
        <FormDateField
          name="publicationDate"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__PUBLICATION_DATE"
              defaultMessage="Datum vyhlášení"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.PUBLICATION_DATE)}
        />
        <FormDateField
          name="feasibility"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__FEASIBILITY"
              defaultMessage="Vykonatelnost"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.FEASIBILITY)}
        />
        <FormLocalDateTimeField
          name="closingDate"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__CLOSING_DATE"
              defaultMessage="Datum uzavření"
            />
          }
          disabled={!hasPermission(UIElement.CourtFile.CLOSING_DATE)}
        />
        {mode !== DetailMode.VIEW && (
          <FormTextField
            name="proceedingDuration"
            type="number"
            label={
              <FormattedMessage
                id="ES__COURT_FILES__DETAIL__FIELD_LABEL__STATS_DATE"
                defaultMessage="Délka řízení ve dnech"
              />
            }
            disabled={!hasPermission(UIElement.CourtFile.PROCEEDING_DURATION)}
          />
        )}
        {mode === DetailMode.VIEW && (
          <FormCustomField
            name="proceedingDurationCalculation"
            label={
              <FormattedMessage
                id="ES__COURT_FILES__DETAIL__FIELD_LABEL__PROCEEDING_DURATION"
                defaultMessage="Délka řízení"
              />
            }
            disabled={true}
          >
            <TextField
              disabled={true}
              value={`${proceedingDuration} (${proceedingDurationCalculation})`}
            />
          </FormCustomField>
        )}
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__PLENARY_OPINIONS_PANEL_TITLE"
            defaultMessage="Stanoviska pléna"
          />
        }
        ref={contestedOpinionPanel}
        expandable={true}
        summary={<FormTableCount items={contestedOpinions} />}
      >
        <FormTableField
          name="contestedOpinions"
          labelOptions={{
            hide: true,
          }}
          ToolbarComponent={EmptyComponent}
          layoutOptions={{ noSpacing: true }}
          maxRows={5}
          showDetailBtnCond={() => false}
          columns={contestedOpinionsColumns}
          disabled={!hasPermission(UIElement.CourtFile.CONTESTED_OPINIONS)}
        />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__MERGED_FILES_PANEL_TITLE"
            defaultMessage="Spojení spisů"
          />
        }
        ref={mergedFilesPanel}
        expandable={true}
        summary={<FormTableCount items={mergedFiles} />}
      >
        <TableField
          value={mergedFiles}
          onChange={noop}
          ToolbarComponent={EmptyComponent}
          showRadioCond={stubFalse}
          maxRows={5}
          showDetailBtnCond={() => false}
          columns={mergedFilesColumns}
        />
      </FormPanel>
      {mode !== DetailMode.NEW && (
        <RunningDeadlineField
          disabled={!hasPermission(UIElement.CourtFile.RUNNING_DEADLINES)}
        />
      )}
      <NoteField
        disabled={disabledNotes || !hasPermission(UIElement.CourtFile.NOTES)}
        source={noteSource}
      />
    </>
  );
}
