import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormAutocomplete,
  FormDateField,
  FormDateTimeField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextField,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { RunningDeadlineField } from '@composite/deadlines/running-deadline-field';
import { NoteField } from '@composite/notes/note-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { EsFile } from '@models';
import { Agenda, Role, UIElement } from '@enums';
import { useSettlementMethods } from '../../../dict-settlement-method/dict-settlement-method-api';
import { useSubmissionContents } from '../../../dict-submission-content/dict-submission-content-api';
import { NOTE_SOURCE } from '../../../es-file/es-file-hooked-sources';
import { useSprFilePermission } from '../../../es-file/es-file-permission';
import { useUserRoles } from '../../../role/role-api';

export function GeneralFields() {
  const { agendas, states } = useContext(StaticDataContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { source, isLocked, mode } =
    useContext<DetailHandle<EsFile>>(DetailContext);

  const solverUsers = useUserRoles({
    filters: [
      {
        field: 'role.id',
        operation: ApiFilterOperation.EQ,
        value: Role.SOLVER,
      },
      {
        field: 'agendas.id',
        operation: ApiFilterOperation.EQ,
        value: Agenda.SPR,
      },
    ],
  });
  const indexUsers = useUserRoles({
    filters: [
      {
        field: 'role.id',
        operation: ApiFilterOperation.EQ,
        value: Role.INDEX,
      },
      {
        field: 'agendas.id',
        operation: ApiFilterOperation.EQ,
        value: Agenda.SPR,
      },
    ],
  });
  const submissionContents = useSubmissionContents();
  const agendaSource = useStaticListSource(agendas);
  const stateSource = useStaticListSource(states);
  const settlementMethods = useSettlementMethods(
    eqFilterParams({ field: 'agenda.id', value: Agenda.SPR })
  );

  const disabledNotes = isLocked || mode !== DetailMode.VIEW;
  const noteSource = hookedSources[NOTE_SOURCE]?.source;

  /**
   * Permissions.
   */
  const { hasPermission } = useSprFilePermission(source.data);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__SPR_FILES__DETAIL__GENERAL_PANEL_TITLE"
            defaultMessage="Spis"
          />
        }
      >
        <FormTextField
          name="number.value"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.FILE_NUMBER)}
        />
        <FormTextField
          name="jid"
          label={
            <FormattedMessage
              id="ES__COURT_FILES__DETAIL__FIELD_LABEL__JID"
              defaultMessage="JID"
            />
          }
          disabled={true}
        />
        <FormTextField
          name="barCode"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__BAR_CODE"
              defaultMessage="Čárový kód"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.BAR_CODE)}
        />
        <FormDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Datum vytvoření"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.CREATED)}
        />
        <FormDateField
          name="deliveryDate"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__DELIVERY_DATE"
              defaultMessage="Datum doručení"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.DELIVERY_DATE)}
        />
        <FormTextField
          name="incomingNumber"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__INCOMING_NUMBER"
              defaultMessage="Došlé číslo"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.INCOMING_NUMBER)}
        />
        <FormSelect
          name="agenda"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__AGENDA"
              defaultMessage="Agenda"
            />
          }
          required
          source={agendaSource}
          disabled={!hasPermission(UIElement.SprFile.AGENDA)}
          valueIsId={true}
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav"
            />
          }
          source={stateSource}
          tooltipMapper={(o) => o.name}
          disabled={!hasPermission(UIElement.SprFile.STATE)}
          valueIsId={true}
        />
        <FormAutocomplete
          name="submissionContent"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__SUBMISSION_CONTENT"
              defaultMessage="Obsah podání"
            />
          }
          autoFocus={true}
          source={submissionContents}
          labelMapper={autocompleteLabelMapper}
          freeSolo={true}
          required={true}
          disabled={!hasPermission(UIElement.SprFile.SUBMISSION_CONTENT)}
        />
        <FormAutocomplete
          name="indexUserRole"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__INDEX_USER_ROLE"
              defaultMessage="Vedoucí rejstříku"
            />
          }
          source={indexUsers}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasPermission(UIElement.SprFile.INDEX_USER_ROLE)}
        />
        <FormAutocomplete
          name="solverUserRoles"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__SOLVER_USER_ROLES"
              defaultMessage="Řešitelé"
            />
          }
          source={solverUsers}
          labelMapper={autocompleteLabelMapper}
          multiple={true}
          disabled={!hasPermission(UIElement.SprFile.SOLVER_USER_ROLES)}
        />
        <FormAutocomplete
          name="settlementMethods"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__SETTLEMENT_METHODS"
              defaultMessage="Způsob vyřízení"
            />
          }
          source={settlementMethods}
          multiple={true}
          labelMapper={autocompleteLabelMapper}
          disabled={!hasPermission(UIElement.SprFile.SETTLEMENT_METHODS)}
        />
        <FormDateField
          name="settlementDate"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__SETTLEMENT_DATE"
              defaultMessage="Datum vyřízení"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.SETTLEMENT_DATE)}
        />
        <FormLocalDateTimeField
          name="closingDate"
          label={
            <FormattedMessage
              id="ES__SPR_FILES__DETAIL__FIELD_LABEL__CLOSING_DATE"
              defaultMessage="Datum uzavření"
            />
          }
          disabled={!hasPermission(UIElement.SprFile.CLOSING_DATE)}
        />
      </FormPanel>
      {mode !== DetailMode.NEW && (
        <RunningDeadlineField
          disabled={!hasPermission(UIElement.SprFile.RUNNING_DEADLINES)}
        />
      )}
      <NoteField
        disabled={disabledNotes || !hasPermission(UIElement.SprFile.NOTES)}
        source={noteSource}
      />
    </>
  );
}
