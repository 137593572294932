import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import {
  DetailContext,
  DetailMode,
  FormHandle,
  TableFieldToolbarButton,
  eqFilterParams,
  useEventCallback,
} from '@eas/common-web';
import { getDefaultContactType } from '@modules/dict-contact-type/dict-contact-type-api';
import { SubjectHistoryContext } from '@modules/subject/subject-history-context';
import { useIsdsSearchSubjectSchema } from '@composite/contact/contact-schema';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { Subject, SubjectUnion } from '@models';
import { EvidenceApiUrl } from '@enums';
import { callApi as addContactApiCall } from '../add-contact-hook';
import { callApi as editContactApiCall } from '../edit-contact-hook';
import { SearchDataBoxDialog } from './search-data-box-dialog';
import { useSearchDataBoxDialog } from './search-data-box-hook';
import { SearchDataBoxResultDialog } from './search-data-box-result-dialog';

interface SearchDataBoxProps {
  formRef?: FormHandle<Subject> | null;
  disabled: boolean;
}

export function SearchDataBoxButton({ disabled, formRef }: SearchDataBoxProps) {
  const intl = useIntl();

  const subjectHistoryContext = useContext(SubjectHistoryContext);
  const { mode, source, refreshAll } = useContext(DetailContext);

  const { handleSubmit, resultRef, result } = useSearchDataBoxDialog();
  const validationSchema = useIsdsSearchSubjectSchema();

  const resetHistorySource = useEventCallback(() => {
    subjectHistoryContext?.historySource.reset();
    subjectHistoryContext?.historySource.loadMore();
  });

  return (
    <>
      <DetailActionButton
        promptKey="SEARCH_DATA_BOX"
        submitCallback={handleSubmit}
        buttonLabel={intl.formatMessage({
          id: 'ES__SUBJECTS__ACTIONBAR__SEARCH_DATA_BOX',
          defaultMessage: 'Vyhledat datovou schránku',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__SUBJECTS__DIALOG_SEARCH_DATA_BOX__TITLE',
          defaultMessage: 'Vyhledání datové schránky',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__SUBJECTS__SEARCH_DATA_BOX__TEXT',
          defaultMessage: 'Vyplňte údaje o subjektu.',
        })}
        FormFields={() => (
          <SearchDataBoxDialog formRef={formRef} initData={true} />
        )}
        confirmLabel={
          <FormattedMessage
            id="ES__CONTACTS_TOOLBAR__EDIT_CONTACT__SEARCH_BUTTON"
            defaultMessage="Vyhledat"
          />
        }
        ButtonComponent={({ onClick, label }) => (
          <TableFieldToolbarButton
            IconComponent={SearchIcon}
            show={true}
            disabled={disabled}
            title={label}
            onClick={onClick}
            color="default"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'ES__CONTACTS_TOOLBAR__EDIT_CONTACT__BUTTON',
                defaultMessage: 'Vyhledat datovou schránku',
              })}
            </Typography>
          </TableFieldToolbarButton>
        )}
        formValidationSchema={validationSchema}
        dialogWidth={1100}
        modes={[DetailMode.EDIT, DetailMode.VIEW, DetailMode.NEW]}
      />
      <DetailActionButton
        ref={resultRef}
        promptKey="SEARCH_DATA_BOX_RESULT"
        submitCallback={async ({ selectedSubject, originalContact }) => {
          const {
            dataBoxId,
            subjectType,
            address,
            givenName,
            birthDate,
            ico,
            firmName,
            lastName,
            publicAuthority,
          } = selectedSubject;

          const parsedAddress = {
            ...address,
            country: null,
            zipCode: null,
            descriptiveNumber: address.numberInMunicipality,
            orientationNumber: address.numberInStreet,
          };

          if (address.state) {
            const state = await loadCountry(address.state);

            if (state) {
              parsedAddress['country'] = state;
            }
          }

          if (address.zipCode) {
            const zipCode = await loadZipCode(address.zipCode);

            if (zipCode) {
              parsedAddress['zipCode'] = zipCode;
            }
          }

          const contactType = await getDefaultContactType();

          const contact = {
            type: contactType,
            firstName: givenName,
            lastName,
            birthDate,
            dataBox: { identifier: dataBoxId, type: subjectType },
            address: parsedAddress,
          };

          if (mode === DetailMode.VIEW) {
            if (originalContact) {
              await editContactApiCall(source.data.id, {
                ...originalContact,
                ...contact,
              }).raw();
            } else {
              await addContactApiCall(source.data.id, contact).raw();
            }
            refreshAll();
          } else {
            const detailValues = formRef?.getFieldValues();
            const contacts = detailValues?.contacts ?? [];

            formRef?.setFieldValues({
              ...detailValues,
              firstName: givenName,
              lastName,
              birthDate,
              ico,
              companyName: firmName,
              isPublicAuthority: publicAuthority,
              type: subjectType,
              selectedContact: undefined,
              contacts: originalContact
                ? [
                    ...contacts.filter((c) => c.id !== originalContact.id),
                    { ...originalContact, ...contact },
                  ]
                : [...contacts, { ...contact, id: uuidv4() }],
            } as SubjectUnion);
          }
          if (mode === DetailMode.EDIT) {
            resetHistorySource();
          }
        }}
        buttonLabel={intl.formatMessage({
          id: 'ES__SUBJECTS__ACTIONBAR__SEARCH_DATA_BOX_RESULT',
          defaultMessage: 'Zobrazit nalezené subjekty',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__SUBJECTS__SEARCH_DATA_BOX_RESULT__TITLE',
          defaultMessage: 'Vyhledání datové schránky',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__SUBJECTS__SEARCH_DATA_BOX_RESULT__TEXT',
          defaultMessage: 'Vyberte subjekt, jehož údaje chcete převzít.',
        })}
        dialogWidth={1200}
        modes={[]}
        formInitialValues={{
          ...result?.subject,
          subjects: result?.subjects,
          maxResultCountReached: result?.maxResultCountReached,
        }}
        closeCallback={() => {
          if (mode !== DetailMode.NEW) {
            resetHistorySource();
          }
        }}
        dialogShowClose={false}
        dialogShowConfirm={false}
        formValidationSchema={validationSchema}
        FormFields={() => (
          <>
            <SearchDataBoxDialog />
            <SearchDataBoxResultDialog />
          </>
        )}
      />
    </>
  );
}

const loadZipCode = async (zipCode: string) => {
  const response = await fetch(
    `${EvidenceApiUrl.DICT_ZIP_CODES}/autocomplete`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(
        eqFilterParams({
          field: 'code',
          value: zipCode,
        })
      ),
    }
  );

  const zipCodes = await response.json();

  return zipCodes?.items?.[0];
};

const loadCountry = async (country: string) => {
  const response = await fetch(
    `${EvidenceApiUrl.DICT_COUNTRIES}/autocomplete`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(
        eqFilterParams({
          field: 'code',
          value: country,
        })
      ),
    }
  );

  const states = await response.json();

  return states?.items?.[0];
};
