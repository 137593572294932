import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyComponent, TableColumn, TableColumnAlign } from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { SprFile } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useSprColumns } from '../spr-file-columns';

export function useColumns(): TableColumn<SprFile>[] {
  const intl = useIntl();

  const {
    columnJid,
    columnDeliveryDate,
    columnIncomingNumber,
    columnSubmissionContent,
    columnWriters,
    columnSolverUserRoles,
  } = useSprColumns();

  return [
    {
      datakey: 'id',
      name: intl.formatMessage({
        id: 'ES__SPR_FILES_NEW__TABLE__COLUMN__REDIRECT',
        defaultMessage: 'Přesměrování',
      }),
      NameComponent: EmptyComponent,
      width: 50,
      CellComponent: function Cell(props) {
        return (
          <>
            <Redirect
              url={EvidenceBrowserUrl.SPR_FILES_NEW}
              id={props.value}
              showRedirectInCurrent={false}
            />
          </>
        );
      },
      align: TableColumnAlign.LEFT,
    },
    columnDeliveryDate,
    columnIncomingNumber,
    columnJid,
    columnSubmissionContent,
    columnWriters,
    columnSolverUserRoles,
  ];
}
